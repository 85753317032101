.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  background-image: url("bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: white;
}

h1 {
  margin-top: 20vh;
  font-size: 45px;
}
